<template>
  <div class="admire-life">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div ref="tabsBox" class="tabsBox">
          <ul class="tabsListBox">
            <li
              v-for="(item, index) in goodsList"
              :key="index"
              @click="gotoDetail(item)"
            >
              <div class="listImgBox">
                <img :src="item.goodCover">
                <div v-if="item.isShowIcon === 1" class="vipTip">
                  <img :src="item.topIconImg">
                </div>
              </div>
              <h3 class="listTitle">
                {{ item.goodName }}
              </h3>
              <div class="listPriceBox">
                <div class="listPrice">
                  <h3>
                    ¥<em>{{ item.vipPrice }}</em><span>¥{{ item.goodPrice }}</span>
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import Vue from 'vue'
import { List, PullRefresh, Swipe, SwipeItem, Tab, Tabs } from 'vant'

Vue.use(Tab)
Vue.use(Tabs)

Vue.use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(List).use(PullRefresh)
import { fetchTravelGoods } from '@/services/goods.js'
import { wxConfigInit } from '@/utils/wx-api'
import { isWeChat } from '@/utils/common/validate'
import { loginWithPhone } from '@/services/activity/admire-life'

export default {
  name: 'AdmireLife',
  components: {
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      lifeOrder: null, // 仰生活订单
      page: 1,
      goodsList: [],
      phone: '',
      timestamp: '',
      sign: ''
    }
  },
  mounted() {
    const { mobile, order_num, timestamp, sign } = this.$route.query
    this.phone = mobile
    this.lifeOrder = order_num
    this.timestamp = timestamp
    this.sign = sign

    this.handleLogin()
  },
  methods: {
    /**
     * 自动登录 - 仰生活
     * 2021-07-16 17:51:54
     * @author SnowRock
     */
    handleLogin() {
      const { phone } = this
      loginWithPhone({
        mobile: phone,
        codeNumber: '74416254'
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          const { mid, token, codeNumber } = data
          localStorage.setItem('uid', mid)
          localStorage.setItem('token', token)
          localStorage.setItem('recode', codeNumber)
          this.getGiftGoods()
        }
      })
    },
    handleSetShare() {
      if (isWeChat()) {
        const shareInfo = {
          title: '悦淘会员，精彩无限购', // 分享标题
          desc: '专享16大权益', // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-05-21/02/yuelvhuijvQ16Mgr6Z1589999698.jpg' // 分享图标
        }
        wxConfigInit(shareInfo)
      }
    },
    // 跳转详情
    gotoDetail(item) {
      this.$router.push({
        path: '/goodsDetailZY',
        query: {
          goodsId: item.goodId,
          skuid: item.productSkuId,
          activityId: '0',
          lifeOrder: this.lifeOrder ? encodeURIComponent(this.lifeOrder) : '',
          timestamp: this.timestamp,
          sign: this.sign,
          isLife: 1
        }
      })
    },
    /**
     * 获取列表列表
     * 2021-07-16 13:53:27
     * @author SnowRock
     */
    getGiftGoods() {
      const body = {
        uid: localStorage.getItem('uid') || '', // 用户id
        page: this.page,
        categoryId: '21',
        activityId: '31,320'
      }
      fetchTravelGoods(body).then((res) => {
        this.loading = false
        if (this.refreshing) {
          this.goodsList = []
          this.refreshing = false
        }
        if (Number(res.code) === 200) {
          this.goodsList = res.data
          this.finished = true
        }
      })
    },
    /**
     * 刷新列表
     * 2021-07-16 13:51:20
     * @author SnowRock
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // this.goodsList = []
      this.page = 1

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getGiftGoods()
    },
    /**
     * 刷新列表
     * 2021-07-16 13:51:20
     * @author SnowRock
     */
    onLoad() {
      this.page++
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getGiftGoods()
    }
  }
}
</script>
<style lang="less" scoped>
.admire-life {
  width: 100%;
  height: 100%;
  .tabsBox {
    background: #f6f6f6;
    padding: 15px;

    .tabsListBox {
      li {
        background: #fff;
        width: 100%;
        height: 274px;
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 8px;
        box-sizing: border-box;

        .listImgBox {
          width: 100%;
          height: 185px;
          background: #ccc;
          position: relative;
          overflow: hidden;

          .vipTip {
            width: 78px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 15px;

            img {
              width: 78px;
              height: 32px;
            }
          }
        }

        .listTitle {
          width: 100%;
          padding: 15px;
          font-size: 15px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        .listPriceBox {
          background: #000;
          width: 100%;

          .listPrice {
            width: 70%;
            float: left;
            text-align: left;

            h3 {
              color: #b1291a;
              font-size: 18px;

              em {
                font-size: 24px;
                font-style: normal;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                text-decoration: line-through;
              }
            }
          }

          .growthValue {
            float: right;
            min-width: 95px;
            padding: 0 5px;
            height: 22px;
            background: #1a1716;
            border-radius: 11px;
            line-height: 22px;
            text-align: left;
            color: #fff;
            font-size: 12px;
            font-family: PingFang SC Regular, PingFang SC Regular-Regular;
            font-weight: 400;

            img {
              width: auto;
              height: 18px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.dir {
  height: 40px;
}
</style>
