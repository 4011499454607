
/**
 * 仰生活
 * 2021-07-16 17:45:24
 * @author SnowRock
 */
import request from '@/services/request'

/**
 * 仰生活登录
 * 2021-07-16 17:46:39
 * @author SnowRock
 * @param params
 * @returns {Promise<*|undefined>}
 */
export function loginWithPhone(params) {
  return request({
    url: '/app/member/v4/registerMobile',
    method: 'post',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 支付成功的回调方法
 * 2021-08-06 14:37:33
 * @author SnowRock
 * @param isSuccess 是否支付成功
 */
export function paySuccess(isSuccess) {
  if (window.android && window.android.paySuccess) {
    window.android && window.android.paySuccess(isSuccess)
  }
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.paySuccess && window.webkit.messageHandlers.paySuccess.postMessage) {
    window.webkit.messageHandlers.paySuccess.postMessage(isSuccess)
  }
}
